.ffw-page-title {
    margin-left: -10px;
}
.ffw-page-title-row {
    margin-top: 50px;
    margin-bottom: 5px;
    border-bottom: 1px solid #dddddd;
}

.ffw-page-title-row > h1 {
    margin-bottom: 10px;
}