$successBgColor: $success-color;
$infoBgColor: $info-color;;
$warningBgColor: $warning-color;
$errorBgColor: $danger-color;
$toastr-width: 420px;

@import '~react-redux-toastr/src/styles/index.scss';

$toastr-width-bottom: 100%;

.ffw-toastr {
  font-family: $font-family-sans-serif;
  color: $font-color;
}

.redux-toastr {
  .top-right, .top-left, .top-center {
    margin-top: 70px;
  }
  .bottom-center {
    margin-left: -($toastr-width-bottom / 2);
    width: $toastr-width-bottom !important;
  }
}

.redux-toastr .toastr .rrt-middle-container {
  padding: 25px 5px;
  width: 70%;
}
