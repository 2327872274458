@import 'paper-dashboard';
@import '../../App';
@import 'toastr';

.ffw-container {
  padding-left: 70px;
  padding-right: 70px;
}

.ffw-btn-options {
  padding: 0px 0px 0px 5px !important;
  height: 22px !important;
  font-size: 13px !important;
  text-transform: none !important;
  font-weight: normal !important;
  margin-left: 10px;
  border: 1px solid #c0c0c0;
}

.ffw-btn-option-create {
  padding: 0px 20px 0px 15px !important;
}

.ffw-btn-option-download {
  padding: 0px 20px 0px 15px !important;
  color: #094653;
}

.ffw-btn-options-type {
  color: #094653 !important; 
}

.ffw-modal-header {
  font-size: 20px;
  color: #094653;
}

.ffw-border-card {
  border-radius: 2px !important;
  border-left: 40px solid #55A09F !important;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #00AAC6;
}

.DateInput_input__small {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .2px;
  padding: 7px 7px 5px;
}

.DateRangePickerInput {
  width: 100%;
  height: 38px;
}

.DateRangePicker {
  display: block;
}

.ffw-cursor-pointer {
  cursor: pointer;
}

.ffw-form-control {
  height: 21px !important;
  padding: 2px;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

.ffw-select {
  color: #717981;
}

form label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray; 
}

.custom-switch .custom-control-label::after {
  top: calc(2px);
}

.custom-control-label::before {
  top: 0;
}

.ffw-highlight:hover {
  color: $default-states-color !important;
}

.ffw-highlight-danger:hover {
  color: $danger-states-color !important;
}

.ffw-card-login {
  width: 450px;
  padding: 50px;
}

.ffw-campaign-card {
  height: 80px;
  border-radius: 2px !important;
  border-left: 30px solid #55A09F !important;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #DDDDDD !important;
}

.ffw-campaign-title {
  font-size: 24px;
  color: #094653;
  margin-bottom: 0px !important;
}

.ffw-breadcrumb {
  background-color: $primary-states-color;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: $white-color;
  content: ">";
}

.breadcrumb-item {
  color: $white-color;
}

.breadcrumb-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ffw-link-color-alt {
  border-radius: 0;
  color: $default-color !important;
}

.ffw-link-color-alt:hover {
  color: $white-color !important;
  text-decoration: none;
}
