#ffw-campaign-map {
  width: 100%;
  height: 65vh;
}

.ffw-legend {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.ffw-legend > h2 {
  margin: 0;
  margin-bottom: 5px;
}

.ffw-legend-element {
  display: -webkit-box;
}

.ffw-legend-box {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 7px;
}